export const tagData = [
  {
    name: 'All'
  },
  {
    name: '3D'
  },
  {
    name: 'Animation'
  },
  {
    name: 'Illustration'
  },
  {
    name: 'Print'
  },
  {
    name: 'UX/UI'
  },
  {
    name: 'Web'
  },
];