export const navigation = [
  {
    name: 'home',
    link: '/'
  },
  {
    name: 'portfolio',
    link: '/portfolio'
  },
  {
    name: 'resume',
    link: '/resume'
  },
  {
    name: 'blog',
    link: '/blog'
  },
  {
    name: 'contact',
    link: '/contact'
  }
]